import _ from '@lodash';
import axios from 'app/client';
import { getProfile, getSelectedLicenseGroupId } from 'app/store/reducers';
import { responseError, responseErrors } from 'app/utils/helpers';
import { AppThunk } from 'app/store';
import { ScheduledReport } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const createScheduledReport =
	({
		name,
		format,
		frequency,
		reportInfo
	}: {
		name: ScheduledReport['name'];
		format: ScheduledReport['format'];
		frequency: ScheduledReport['frequency'];
		reportInfo: ScheduledReport['reportInfo'];
	}): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());
		const profile = getProfile(getState());

		const data = {
			type: 'report-fleet-escalation',
			tenantId: licenseGroupId,
			userId: profile.id,
			name,
			format,
			schedule: {
				frequency
			},
			reportInfo
		};

		try {
			// const response = await axios.patch(`/api/v1/policy/${licenseGroupId}`, data);
			const response = await axios.post(`/api/v1/report`, data);
			// @ts-ignore
			if (responseError(response)) {
				dispatch(appActions.alert('failed to create scheduled report', 'warning'));
			} else {
				dispatch(appActions.alert('scheduled report created', 'success'));
			}
			dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
		} catch (error) {
			dispatch(appActions.handleError(error));
		}
	};

export const deleteScheduledReports =
	(scheduledReportIds: ScheduledReport['id'][]): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());

		try {
			// TEMP::missing bulk delete so looping per action
			const responses = await Promise.all(
				scheduledReportIds.map(scheduledReportId =>
					axios.delete(`/api/v1/report/${licenseGroupId}/${scheduledReportId}`)
				)
			);
			if (responseErrors(responses).length) {
				dispatch(appActions.alert('failed to delete some scheduled reports', 'warning'));
			} else {
				dispatch(appActions.alert('scheduled reports deleted', 'success'));
			}
			dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
		} catch (error) {
			dispatch(appActions.handleError(error));
		}
	};
